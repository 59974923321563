@use "src/design-system/styles/variables" as variables;
@use "src/design-system/styles/utils/forms" as forms;

@mixin for-low-contrast($color) {
  @if (low-contrast($color)) {
    @content;
  }
}

// Note: Every custom form control is styled in its own component's stylesheet
body {

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  select {
    height: 44px;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  textarea,
  .textarea,
  select {
    border: 1px solid variables.$gray-300;
    border-radius: variables.$border-radius-input;
    padding: variables.$padding-input-y variables.$padding-input-x;

    color: variables.$gray-900;
    background-color: variables.$white;
    box-shadow: 0 1px 2px variables.$input-shadow;

    font-size: variables.$font-size-16;
    line-height: variables.$line-height-24;

    &::placeholder {
      @include forms.input-placeholder;
    }

    &:focus {
      @include forms.input-focus;
    }

    &:disabled {
      @include forms.input-disabled;
    }

    &.theme-light {
      background-color: map-get($theme, hwhite);
    }

    &.theme-exercise {
      padding: map-get($paddings, minified) map-get($paddings, minified) * 3;
      background-color: rgba(map-get($theme, hfooterbg), 0.5);
      border-color: transparent;
      border-radius: 1.5rem;
      height: 4rem;

      &:focus {
        box-shadow: unset;
      }

      &::placeholder {
        color: map-get($theme, hblack);
        font-weight: 400;
      }
    }
  }

  .icon-input {

    input[type="text"],
    input[type="password"],
    input[type="email"],
    textarea,
    .textarea {
      padding-left: variables.$padding-icon-input-left;
    }

    select {
      -webkit-app§earance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      padding-left: variables.$padding-icon-input-left;
    }

    input[type="checkbox"],
    input[type="radio"] {
      &.quiz-answer {
        &+label.checkbox {
          padding-left: variables.$padding-icon-input-left + variables.$checkbox-size + 6px; // TODO for design system

          &:before {
            left: variables.$padding-icon-input-left;
          }

          &:after {
            left: variables.$padding-icon-input-left + 6px; // TODO for design system
          }
        }
      }
    }
  }

  label {
    color: variables.$gray-700;
  }

  .hform-group {
    padding: map-get($paddings, minified) map-get($paddings, minified) map-get($paddings, minified) 0;

    label {
      display: block;
      margin: 0 0 6px;

      &.checkbox {
        margin-top: 10px;
      }
    }
  }

  .button,
  button,
  input[type="button"],
  input[type="submit"] {
    min-width: 20px;
    font-size: 1.4rem;
    height: 4rem;
    line-height: 1;
    padding: map-get($paddings, minified) map-get($paddings, minified);
    border-radius: 2rem;
    font-weight: 600;
    transition:
      background-color $transition-time linear,
      color $transition-time linear;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: 1px solid transparent;

    &.btn-pure {
      min-width: none;
    }

    &.btn-with-icon {
      display: inline-flex;
      align-items: center;
      padding: 1rem 1.4rem;

      .uil,
      .typcn {
        margin-right: 0.8rem;
        font-size: 2rem;
      }
    }

    &.btn-primary-900 {
      @include forms.button(variables.$btn-primary-900);
    }

    &.btn-primary-600 {
      @include forms.button(variables.$btn-primary-600);
    }

    &.btn-blue-light-600 {
      @include forms.button(variables.$btn-blue-light-600);
    }

    &.btn-success-600 {
      @include forms.button(variables.$btn-success-600);
    }

    &.btn-success-500 {
      @include forms.button(variables.$btn-success-500);
    }

    &.btn-warning-500 {
      @include forms.button(variables.$btn-warning-500);
    }

    &.btn-error-600 {
      @include forms.button(variables.$btn-error-600);
    }

    &.btn-error-500 {
      @include forms.button(variables.$btn-error-500);
    }

    &.btn-gray-50 {
      @include forms.button(variables.$btn-gray-50);
    }

    &.btn-error-50 {
      @include forms.button(variables.$btn-error-50)
    }

    &.btn-white {
      @include forms.button(variables.$btn-white)
    }

    &.btn-link {
      @include forms.button(variables.$btn-link)
    }

    &.btn-lg {
      font-size: variables.$font-size-16;
      height: 44px;
      border-radius: variables.$border-radius-default;
      box-shadow: 0 1px 2px 0 variables.$button-lg-shadow;
      padding-left: map-get($paddings, standard);
      padding-right: map-get($paddings, standard);
    }

    @each $color-name, $color in $theme {
      &.theme-#{$color-name} {
        $theme-color: (
          "background-color": $color,
          "hover-text-color": $color,
          // Keep disabled state style for "legacy buttons" until every button is replaced with modern one.
          // TODO remove after adding btn-{color}-{brightness} classes to every button!
          "disabled-color": #111111,
          "disabled-text-color": variables.$white
        );

      @include forms.button($theme-color);
    }
  }

  &:active {
    box-shadow: 4px 4px 20px rgba(map-get($theme, hblack), 0.1) inset;
  }

  &.lg {
    min-width: 50px;
    height: 50px;
    border-radius: 25px;
  }

  &.text-lg {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }

  &.shadow {
    transition:
      background-color $transition-time linear,
      color $transition-time linear,
      box-shadow $transition-time linear;
    box-shadow: 0 0 10px rgba(map-get($theme, hblack), 0.1);
  }

  &:hover {
    &.shadow {
      box-shadow: 0 0 10px rgba(map-get($theme, hblack), 0.2);
    }
  }
}

.button {
  line-height: 1.8rem;
}

input[type="checkbox"],
input[type="radio"] {
  display: none;

  .test {
    display: inline;

  }

  &+label.checkbox,
  &+label.radio {
    display: inline-block;
    padding: 2px 10px 2px 40px;
    border-radius: 5px;
    border: 0;
    box-shadow: 4px 4px 10px rgba(map-get($theme, hblack), 0.1);
    color: map-get($theme, hlabelgray);
    line-height: 2rem;
    position: relative;
    cursor: pointer;

    &,
    &:before,
    &:after {
      transition:
        background-color $transition-time linear,
        color $transition-time linear,
        box-shadow $transition-time linear;
    }

    &:before,
    &:after {
      $size: 30px;
      font-size: 1.5rem;
      font-weight: 300;
      font-family: "typicons";
      display: inline-block;
      width: $size;
      height: $size;
      color: map-get($theme, hwhite);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 5px 0 0 5px;
      text-align: center;
      line-height: 3.2rem;
    }

    &:before {
      background-color: map-get($theme, hlabelgray);
      transition-delay: 0s;
      content: "\e122";
    }

    &:after {
      background-color: map-get($theme, htransparent);
      color: map-get($theme, htransparent);
      transition-delay: 0.3s, 0.3s, 0.3s;
      content: "\e11e";
    }

    &:hover {
      box-shadow: 4px 4px 10px rgba(map-get($theme, hblack), 0.2);

      &:before {
        background-color: lighten(map-get($theme, hlabelgray), 5);
      }
    }

    &:active {
      box-shadow:
        4px 4px 10px rgba(map-get($theme, hblack), 0.2),
        2px 2px 5px rgba(map-get($theme, hblack), 0.05) inset;
    }
  }

  &:checked+label.checkbox,
  &:checked+label.radio {
    &:before {
      color: map-get($theme, htransparent);
    }

    &:after {
      background-color: map-get($theme, hprimarydark);
      color: map-get($theme, hwhite);
    }
  }

  &.quiz-answer {
    &+label.checkbox {
      box-shadow: none;
      border-radius: 15px 0 0 15px;
      height: auto;
      text-transform: none;
      font-size: 1.4rem;

      &,
      &:before,
      &:after {
        transition:
          transform $transition-time ease-in-out,
          background-color $transition-time linear,
          color $transition-time linear,
          box-shadow $transition-time linear,
          border-color $transition-time linear;
      }

      &:before,
      &:after {
        $size: variables.$checkbox-size;
        border-radius: 12px;
        content: "";
        width: $size;
        height: $size;
      }

      &:before {
        border: 1px solid variables.$gray-300;
        color: map-get($theme, hlabelgray);
        background-color: variables.$white;
      }

      &:after {
        transition-delay: 0s, 0s, 0s, 0s, 0s;
        width: 12px;
        height: 12px;
        left: 6px;
        top: 6px;
      }
    }

    &:checked+label.checkbox {
      background-color: lighten(map-get($theme, hprimarylight), 6);
      border-radius: 12px 7px 7px 12px;

      &:before {
        border-color: map-get($theme, hprimarydark);
        background-color: map-get($theme, hwhite);
      }
    }

    &.multi-choice {
      &+label.checkbox {
        border-radius: 7px;

        &:before,
        &:after {
          border-radius: 7px;
          content: "";
        }

        &:after {
          line-height: 12px;
        }
      }

      &:checked+label.checkbox {
        &:before {
          transform: none;
        }

        &:after {
          transform: scale(1.4);
          background-color: transparent;
          color: map-get($theme, hprimarydark);
          content: "\e11e";
        }
      }
    }


    &.radio-style {
      &+label.checkbox {
        border-radius: 7px;

        &:before,
        &:after {
          // border-radius: 7px;
          content: "";
        }

        &:after {
          line-height: 14px;
        }
      }

      &:checked+label.checkbox {
        &:before {
          transform: none;
          background-color: map-get($theme, hprimary-600);
        }

        &:after {
          transform: scale(1.2);
          background-color: transparent;
          color: map-get($theme, hwhite);
          content: "\e11e";
        }
      }
    }
    &.no-bg {
      &:checked+label.checkbox {
        background-color: transparent;
      }
    }

    &:disabled+label {
      cursor: default;
    }

    &.quiz-disabled {
      opacity: 0.5;
    }
  }

  $settings-chbx-padding: 7px;

  &+label.checkbox.settings-checkbox,
  &+label.radio.settings-checkbox {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    padding: $settings-chbx-padding $settings-chbx-padding $settings-chbx-padding 35px;
    font-size: 1.2rem;

    &,
    &:before,
    &:after {
      transition-duration: $transition-time;
      transition-delay: 0s;
    }

    &:before,
    &:after {
      line-height: 2.2rem;
    }

    &:before {
      content: " ";
      background-color: transparent;
      width: 20px;
      height: 20px;
      border: 2px solid map-get($theme, hlabelgray);
      top: $settings-chbx-padding;
      left: 5px;
    }

    &:hover {
      box-shadow: none;
      background-color: map-get($theme, hbackgroundgray);

      &:before {
        background-color: transparent;
      }
    }
  }

  &+label.checkbox.settings-checkbox {

    &:before,
    &:after {
      border-radius: 0;
    }

    &:after {
      width: 20px;
      height: 20px;
      top: $settings-chbx-padding;
      left: 5px;
    }

    &.centered {

      &:before,
      &:after {
        top: 50%;
        margin-top: -10px;
      }
    }
  }
}

input[type="checkbox"].show,
input[type="radio"].show {
  display: block;
  appearance: none;
  position: relative;
  width: 22px;
  height: 22px;
  border: 1px solid variables.$gray-300;
  border-radius: 50%;
  background-color: variables.$white;
  cursor: pointer;
  flex-shrink: 0;
}

input[type="checkbox"].show:checked {
  background-color: variables.$primary-600; 
  border-color: variables.$primary-600; 
}

input[type="checkbox"].show:checked::after {
  font-family: "unicons";
  content: "\e9c3";
  color: variables.$white;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"].multi-show,
input[type="radio"].multi-show {
  display: block;
  appearance: none;
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid variables.$gray-300;
  border-radius: 4px;
  background-color: variables.$white;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

input[type="checkbox"].multi-show:checked {
  background-color: variables.$white; 
  border-color: variables.$primary-600; 
}

input[type="checkbox"].multi-show:checked::after {
  font-family: "unicons";
  content: "\e9c3";
  color: variables.$primary-600;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"].multi-show:disabled {
  opacity: 0;
}


input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
input.invalid,
textarea.invalid,
select.invalid {
  border-color: variables.$error-300;

  &:focus {
    outline-color: variables.$error-100;
  }
}

.form {
  .hform-group {
    padding: 10px 0;

    input,
    select,
    textarea,
    .textarea {
      width: calc(100% - 2px);
      margin: 1px;
    }

    textarea {
      resize: none;
    }

    &.no-top-padding {
      padding-top: 0;
    }
  }

  .button {
    margin: 10px 0 0;
  }
}

ul.validation-text {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &>li {
    &:before {
      content: "• ";
    }

    &:only-child {
      &:before {
        display: none;
      }
    }
  }
}
}
